body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.done {
  border-radius: 30px;
  background-color: #302222;
  padding: 5px;
  height: 350px;
}

.device {
  height: 150px;
  margin-top: 30px;
}

.backButton {
  color: white;
  background-color: black;
  border-color: #EC404A;
  border-radius: 20px;
  margin:10px;
  padding:15px;
  font-size: 20px;
  cursor:pointer;
}
